import EmaInput from "components/emaInput/EmaInput";
import EmaButton from "components/emaButton/EmaButton";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "assets/styles/PhoneInput.sass";
import findError from "utils/findError";

const OperatorContactForm = ({
  setIsOpen,
  addContact,
  index,
  selectedContact,
  errors,
}) => {
  const [contact, setContact] = useState(selectedContact);

  function handleChange(e) {
    setContact({
      ...contact,
      [e.target.name]: e.target.value,
    });
  }

  const handleClickSave = () => {
    addContact(contact, index);
  };

  return (
    <div className="slider-panel">
      <div className="slider-header">
        <section className="slider-header-title">Contact Form</section>
        <section className="slider-header-buttons">
          <EmaButton title="Cancel" onClick={() => setIsOpen(false)} />
          <EmaButton title="Save" btnDark onClick={() => handleClickSave()} />
        </section>
      </div>
      <div className="slider-body">
        <div className="ema-card">
          <div className="row">
            <div className="col col-6">
              <EmaInput
                name="firstName"
                label="First Name"
                value={contact.firstName}
                onChange={handleChange}
                error={findError(`[${index}].FirstName`, errors)}
                required
              />
            </div>
            <div className="col col-6">
              <EmaInput
                name="middleName"
                label="Middle Name"
                value={contact.middleName}
                onChange={handleChange}
                error={findError(`[${index}].MiddleName`, errors)}
                required
              />
            </div>
            <div className="col col-6">
              <EmaInput
                name="familyName"
                label="Family Name"
                value={contact.familyName}
                onChange={handleChange}
                error={findError(`[${index}].FamilyName`, errors)}
              />
            </div>
            <div className="col col-6">
              <EmaInput
                type="select"
                options={["Primary Contact", "Secondary Contact"]}
                label="Contact Type"
                name="contactType"
                value={contact.contactType}
                onChange={handleChange}
                error={findError(`[${index}].ContactType`, errors)}
                required
              />
            </div>

            <div className="col-12 col-lg-6 ">
              <label className="phone-input-label" htmlFor="phone">
                Primary Phone{/* <span className="required-red">*</span> */}
              </label>
              <PhoneInput
                className={
                  "phone-input"
                  //classNames("phone-input", {
                  //"phone-error": findError(`[${index}].Phone`, errors),})
                }
                defaultCountry="ET"
                international
                value={contact.primaryPhone}
                onChange={(value) => {
                  handleChange({ target: { name: "primaryPhone", value } });
                }}
                name="primaryPhone"
              />
              <span className="phone-error-message">
                {findError(`[${index}].PrimaryPhone`, errors)}
              </span>
            </div>
            <div className="col col-6">
              <EmaInput
                name="email"
                label="Email"
                value={contact.email}
                onChange={handleChange}
                error={findError(`[${index}].Email`, errors)}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OperatorContactForm;
