import {
  getApi,
  postApi,
  putApi,
  deleteApi,
} from "services/apiEndpoints/axiosWrapper";
import { toast } from "react-toastify";

export const baseUrls = {
  //local: "https://localhost:5001/",
  local: "https://transport.unitelcloud.com/",
  sandbox: "https://transport.unitelcloud.com/",
  qa: "https://transport.unitelcloud.com/",
  staging: "https://transport.unitelcloud.com/",
  prod: "https://transport.unitelcloud.com/",
};
const apiConfig = {
  baseUrls,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 15000,
};

const errorsSeparation = (errors) => {
  let fieldErrors = [];
  let customErrors = [];
  errors.forEach((error) => {
    if (error.key) {
      fieldErrors.push(error);
    } else {
      customErrors.push(error);
    }
  });
  return [fieldErrors, customErrors];
};

const errorsHandler = (error) => {
  let vErrors;
  let errorMessage =
    "Something went wrong. Please try again or call our support if issue persists";
  if (error.response) {
    //console.log(error.response);
    let { data, status } = error.response;
    let { errors } = data;
    if (status === 400) {
      errorMessage = "400: Bad Request";
      if (errors?.length) {
        let [fieldErrors, customErrors] = errorsSeparation(errors);
        let inputErrors;
        let DescErrors;
        if (fieldErrors.length) {
          vErrors = fieldErrors;
          inputErrors = (
            <strong>
              There is one or more missing or invalid required fields
            </strong>
          );
        }
        if (customErrors.length) {
          DescErrors = customErrors.map((err, index) => (
            <p key={index}>{err.errorMessage}</p>
          ));
        }
        errorMessage = (
          <div>
            {inputErrors}
            {DescErrors}
          </div>
        );
      }
    } else if (status === 403) {
      errorMessage = "You do not have permission to perform this action";
    } else if (status === 404) {
      errorMessage = "Record not found";
    } else if (status === 500) {
      errorMessage = "An error occurred";
    }
  }
  toast.error(errorMessage);
  return { success: false, vErrors };
};

const successHandler = (response) => {
  console.log(response);
  let _message =
    response?.data?.message ||
    response?.data?.data ||
    response?.title ||
    " Success";
  toast.success((_message || "").toString());
  return { success: true, ...response };
};

export async function getElements(url, customConfig) {
  return await getApi(url, { ...apiConfig, ...customConfig });
}
export async function putElement(url, data, version) {
  return putApi(url, apiConfig, data, version)
    .then((res) => successHandler(res))
    .catch((error) => errorsHandler(error));
}

export async function postElement(url, data, version) {
  return postApi(url, apiConfig, data, version)
    .then((res) => successHandler(res))
    .catch((error) => errorsHandler(error));
}
export async function deleteElement(url, data, version) {
  return deleteApi(url, apiConfig, data, version)
    .then((res) => successHandler(res))
    .catch((error) => errorsHandler(error));
}
