import EmaButton from "components/emaButton/EmaButton";
import { useState } from "react";
import OperatorForm from "./OperatorForm";
import { postElement } from "services/apiEndpoints/Endpoints";

function FleetOwners({ setIsOpen, getOperators, id }) {
  const [operator, setOperator] = useState({
    authorityType: "Fleet Owner",
    country: "Ethiopia",
    countryCode: "ET",
  });
  const [errors, setFormErrors] = useState([]);

  function handleChange(e) {
    setOperator({
      ...operator,
      [e.target.name]: e.target.value,
    });
  }
  function handleClickSave() {
    //console.log(operator);
    postElement(`/Authority/Operator/${id}/FleetOwner`, operator).then(
      (res) => {
        if (res.success) {
          getOperators();
          setIsOpen(false);
        } else if (res.vErrors) {
          setFormErrors(res.vErrors);
        }
      }
    );
  }
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title">Fleet Owner</section>
        <section className="slider-header-buttons">
          <EmaButton title="Cancel" onClick={() => setIsOpen(false)} />
          <EmaButton
            title="Save"
            btnDark
            onClick={() => handleClickSave(operator)}
          />
        </section>
      </div>
      <div className="slider-body">
        <OperatorForm
          handleChange={handleChange}
          operator={operator}
          disableAuthorityType={true}
          errors={errors}
        />
      </div>
    </div>
  );
}

export default FleetOwners;
