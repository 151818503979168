import React, { useState } from "react";
import "./profile.sass";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { logoutRedirect } from "services/Auth";
import { getCookie } from "services/cookies";
import { NavLink } from "react-router-dom";

const Profile = () => {
  const [openToggle, setOpenToggle] = useState(false);
  let { given_name, family_name } = getCookie("userInfo") || {};

  return (
    <>
      <span className="mx-4">{`${given_name} ${family_name}`}</span>
      <Dropdown
        isOpen={openToggle}
        toggle={() => setOpenToggle(!openToggle)}
        className="profile-dopdown"
      >
        <DropdownToggle
          className="dropdown-toggler profile-avatar"
          onClick={() => setOpenToggle(!openToggle)}
          nav
        >
          {given_name ? given_name.charAt(0).toUpperCase() : ""}
          {family_name ? family_name.charAt(0).toUpperCase() : ""}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <i className="icon-setting-o profile-icon" />
            <span className="profile-menu-text "> Profile Setting</span>
          </DropdownItem>
          <DropdownItem>
            <i className="icon-setting-o profile-icon" />
            <NavLink to={"/system-settings"} className="profile-menu-text">
              System Settings
            </NavLink>
          </DropdownItem>
          <DropdownItem>
            <i className="icon-user-group-o profile-icon" />
            <span className="profile-menu-text ">User Management</span>
          </DropdownItem>
          <hr />
          <DropdownItem>
            <i className="icon-Logout profile-icon" />
            <span className="profile-menu-text" onClick={logoutRedirect}>
              Sign Out
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Profile;
