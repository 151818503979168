import React, { useState } from "react";
import Ports from "./ports/Ports";
import TitleBar from "layout/top-menu-bar/TitleBar";
import { useTranslation } from "react-i18next";
import "view/operational-activity/OperationalActivity.sass";
import Commodities from "./Commodities";

const Setting = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  //const [unmountTabView, setUnmountTabView] = useState(1);

  const onTabChange = (tabNumber) => {
    if (tabNumber !== tab) {
      setTab(tabNumber);
      //setUnmountTabView(true);
    }
  };
  return (
    <div className="operational-activity">
      <div className="operational-sidebar">
        <TitleBar title={"Settings"} pageOptions={null} />

        <div>
          <section
            className={tab === 1 ? "selected" : ""}
            onClick={() => onTabChange(1)}
          >
            {t("Ports & Terminals")}
          </section>
          <hr />
          <section
            className={tab === 2 ? "selected" : ""}
            onClick={() => onTabChange(2)}
          >
            {t("Commodities")}
          </section>
        </div>
      </div>
      <div className="operational-display">
        {tab === 2 ? <Commodities /> : <Ports />}
      </div>
    </div>
  );
};

export default Setting;
