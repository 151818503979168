import EmaInput from "components/emaInput/EmaInput";

import "./FileUpload.sass";
import CountryRegionInput from "components/country-region-input/CountryRegionInput";
import findError from "utils/findError";
import { useTranslation } from "react-i18next";
function OperatorForm({ operator, handleChange, errors, disableInputs }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="ema-card">
        <div className="ema-card-title">{t("Basic")}</div>
        <div className="row">
          <div className="col col-6">
            <EmaInput
              name="company"
              label={t("Company Name")}
              value={operator.company}
              onChange={handleChange}
              error={findError("company", errors)}
              disabled={disableInputs}
              required
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="dba"
              label={t("Trade Name")}
              value={operator.dba}
              onChange={handleChange}
              error={findError("DBA", errors)}
              disabled={disableInputs}
            />
          </div>

          <div className="col col-6">
            <EmaInput
              name="tin"
              maxLength={10}
              label={t("TIN")}
              value={operator.tin}
              onChange={handleChange}
              error={findError("TIN", errors)}
              disabled={disableInputs}
              required
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="website"
              label={t("Website")}
              value={operator.website}
              onChange={handleChange}
              error={findError("Website", errors)}
              disabled={disableInputs}
            />
          </div>
        </div>
      </div>

      <div className="ema-card">
        <div className="ema-card-title">{t("Address")}</div>
        <div className="row">
          <div className="col col-12">
            <EmaInput
              name="addressLine1"
              label={t("Address 1")}
              value={operator.addressLine1}
              onChange={handleChange}
              error={findError("addressLine1", errors)}
              disabled={disableInputs}
            />
          </div>
          <div className="col col-12">
            <EmaInput
              name="addressLine2"
              label={t("Address 2")}
              value={operator.addressLine2}
              onChange={handleChange}
              error={findError("addressLine2", errors)}
              disabled={disableInputs}
            />
          </div>
          <div className="col col-6">
            <EmaInput
              name="city"
              label={t("City")}
              value={operator.city}
              onChange={handleChange}
              error={findError("city", errors)}
              disabled={disableInputs}
            />
          </div>
          <div className="col col-6">
            <CountryRegionInput
              onChange={handleChange}
              value={operator.state}
              name="state"
              error={findError("state", errors)}
              label={t("State")}
              disabled={disableInputs}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OperatorForm;
