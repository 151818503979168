import DataTable from "react-data-table-component";
import authoritiesColumns, { documentColumns } from "./AuthoritiesColumn";
import { useTranslation } from "react-i18next";
import { getElements, postElement } from "services/apiEndpoints/Endpoints";
import { useEffect, useState } from "react";
import AuthorityDocs from "./AuthorityDocs";
import Loading from "components/loading/Loading";
import SliderPanel from "components/sliderPanel/SliderPanel";
import AuthorityForm from "./AuthorityForm";
import { toast } from "react-toastify";

const Authorities = ({ operatorId, disableInputs, getOperators }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNew, setIsOpenNew] = useState(false);

  const [authorities, setAuthorities] = useState([]);
  const [loading, setLoading] = useState(Loading);

  const getAuthorities = () => {
    setLoading(Loading);
    getElements(`/Authority/Operator/${operatorId}/OperatingAuthority`)
      .then((response) => {
        setAuthorities(response?.data?.data || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const postAuthority = (_authorities) => {
    postElement(
      `/Authority/Operator/${operatorId}/OperatingAuthority`,
      _authorities
    ).then((res) => {
      if (res.success) {
        getAuthorities();
        getOperators();
      }
    });
  };

  const onSave = (auth) => {
    const modifiedAuthorities = JSON.parse(JSON.stringify(authorities));
    const foundDuplicateIndex = authorities.findIndex(
      (a) => a.authorityType == auth.authorityType
    );

    if (foundDuplicateIndex >= 0) {
      modifiedAuthorities.splice(foundDuplicateIndex, 1, auth);
    } else {
      modifiedAuthorities.push({
        ...auth,
        operatorId: operatorId,
        operatingAuthorityId: 0,
        documents: [],
      });
    }
    setAuthorities(modifiedAuthorities);
    setIsOpen(false);
    postAuthority(modifiedAuthorities);
  };

  const onAddAuthority = (auth) => {
    const modifiedAuthorities = JSON.parse(JSON.stringify(authorities));
    //check for duplicate
    const foundDuplicate = authorities.find(
      (a) => a.authorityType == auth.authorityType
    );

    if (foundDuplicate) {
      toast.error(`${auth?.authorityType || ""} Authority Type Already Exists`);
    } else {
      modifiedAuthorities.push({
        ...auth,
        operatorId: operatorId,
        documents: [],
      });

      setAuthorities(modifiedAuthorities);
      setIsOpenNew(false);
      postAuthority(modifiedAuthorities);
    }
  };

  const onEdit = (row) => {
    setIsOpen(row);
  };

  useEffect(getAuthorities, []);

  return (
    <div className="ema-card">
      <div className="ema-card-title d-flex align-items-center justify-content-between">
        <div>Operating Authorities</div>
        <i
          className={`mil-plus clickable`}
          onClick={() => setIsOpenNew({})}
          style={{ fontSize: "20px" }}
        />
      </div>
      {loading ? (
        loading
      ) : (
        <div className="summary-list ">
          <DataTable
            columns={authoritiesColumns(
              disableInputs,
              onEdit,
              getAuthorities
            ).map((c) => ({
              ...c,
              name: t(c.name),
            }))}
            data={authorities}
            expandableRows
            expandableRowsComponent={(data) => (
              <AuthorityDocs
                data={data?.data || {}}
                disableInputs={disableInputs}
                onSaveDocs={onSave}
              />
            )}
          />
        </div>
      )}
      <SliderPanel setIsOpen={setIsOpen} isOpen={isOpen} from="bottom">
        <AuthorityForm
          authority={isOpen}
          handleOnSave={onSave}
          setIsOpen={setIsOpen}
          disableType={true}
        />
      </SliderPanel>
      <SliderPanel setIsOpen={setIsOpenNew} isOpen={isOpenNew} from="bottom">
        <AuthorityForm
          authority={{}}
          handleOnSave={onAddAuthority}
          setIsOpen={setIsOpenNew}
        />
      </SliderPanel>
    </div>
  );
};

export default Authorities;
