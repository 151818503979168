import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";
import { useHistory } from "react-router-dom";

const ActiveCard = ({ response }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const setUpTrend = (amount) => {
    let a = amount ? amount * 100 : 0;
    return a + "%";
  };

  const onCardClicked = (authType) => {
    ["Carrier", "Forwarder"];
    history.push({
      pathname: "/freight-operators",
      state: { status: "Authorized", authority_type: authType },
    });
  };

  const cardsValues = [
    {
      label: t("Active Carriers"),
      value: response.carriersActive,
      change: response.trendCarriers,
      changePercentage: setUpTrend(response.trendCarriers),
      changeType: "positive",
      type: "Carrier",
    },
    {
      label: t("Active Freight Forwarders"),
      value: response.forwardersActive,
      change: response.trendForwarders,
      changePercentage: setUpTrend(response.trendForwarders),
      changeType: "negative",
      type: "Forwarder",
    },
    {
      label: t("Active Vehicles"),
      value: response.vehiclesActive,
      change: response.trendVehicles,
      changePercentage: setUpTrend(response.trendVehicles),
      changeType: "positive",
      type: "",
    },
  ];
  return (
    <>
      {cardsValues.map((a) => (
        <div
          className="dashboard-card dash-active-card"
          onClick={() => {
            onCardClicked(a.type);
          }}
        >
          <div>
            <label className="dash-card-sec-titl">{a.label}</label>
            <section className="dash-card-value-big-text">
              {a.value || 0}
            </section>
          </div>
          <div
            className={
              "dash-card-trend " + (a.change <= 0 ? "negative" : "positive")
            }
          >
            {a.changePercentage}
            {a.change >= 0 ? <IoMdTrendingUp /> : <IoMdTrendingDown />}
          </div>
        </div>
      ))}
    </>
  );
};

export default ActiveCard;
