import React, { useEffect } from "react";
import EmaButton from "components/emaButton/EmaButton";
import { useState } from "react";
import OperatorForm from "./OperatorForm";
import { getElements, putElement } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";
import OperatorContact from "./operatorDetails/operator-contacts/OperatorContact";
import { useTranslation } from "react-i18next";
import Authorities from "./operatorDetails/authorities/Authorities";
import Vehicles from "./operatorDetails/vehicles/Vehicles";

const EditOperator = ({ setIsOpen, id, getOperators, disableInputs }) => {
  const { t } = useTranslation();
  const [operator, setOperator] = useState({
    country: "Ethiopia",
    countryCode: "ET",
  });
  const [loading, setLoading] = useState(Loading);
  const [errors, setFormErrors] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [openVehicleForm, setOpenVehicleForm] = useState(false);

  function handleChange(e) {
    setOperator({
      ...operator,
      [e.target.name]: e.target.value,
    });
  }
  function handleClickSave() {
    putElement(`/Authority/Operator/${id}`, operator).then((res) => {
      if (res.success) {
        getOperators();
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  }

  const getSelectedOperators = () => {
    setLoading(Loading);
    getElements(`/Authority/Operator/${id}`).then((res) => {
      setOperator(res.data);
      setLoading(false);
    });
  };

  useEffect(getSelectedOperators, []);
  return (
    <div className="slider-panel">
      <div className="slider-header d-block">
        <div>
          <div className="d-flex justify-content-between">
            <section className="slider-header-title">
              {operator.company}
            </section>
            <section className="slider-header-buttons">
              <EmaButton title={t("Cancel")} onClick={() => setIsOpen(false)} />
              {selectedTab == 1 && (
                <EmaButton
                  title={t("Save")}
                  btnDark
                  onClick={() => handleClickSave(operator)}
                  disabled={disableInputs}
                />
              )}
              {selectedTab == 3 && (
                <EmaButton
                  title={t("Register Vehicle")}
                  btnDark
                  onClick={() => setOpenVehicleForm(true)}
                  disabled={disableInputs}
                />
              )}
            </section>
          </div>
          <div className="custom-tabs-content">
            <section
              className={`custom-tab-item ${
                selectedTab == 1 ? "selected-custom-tab" : ""
              }`}
              onClick={() => setSelectedTab(1)}
            >
              Operator
            </section>
            <section
              className={`custom-tab-item ${
                selectedTab == 2 ? "selected-custom-tab" : ""
              }`}
              onClick={() => setSelectedTab(2)}
            >
              Authorities
            </section>
            {(operator?.authorityType || "")
              .toLowerCase()
              .includes("carrier") && (
              <section
                className={`custom-tab-item ${
                  selectedTab == 3 ? "selected-custom-tab" : ""
                }`}
                onClick={() => setSelectedTab(3)}
              >
                Vehicles
              </section>
            )}
          </div>
        </div>
      </div>
      <div className="slider-body">
        {selectedTab == 1 ? (
          <>
            {loading ? (
              loading
            ) : (
              <>
                <OperatorForm
                  handleChange={handleChange}
                  operator={operator}
                  errors={errors}
                  disableInputs={disableInputs}
                />
                <OperatorContact
                  operatorId={id}
                  disableInputs={disableInputs}
                />
              </>
            )}
          </>
        ) : selectedTab == 2 ? (
          <Authorities
            operatorId={id}
            disableInputs={disableInputs}
            getOperators={getOperators}
          />
        ) : (
          <Vehicles
            operator={{
              operatorId: id,
              company: operator.company,
            }}
            openVehicleForm={openVehicleForm}
            setOpenVehicleForm={setOpenVehicleForm}
          />
        )}
      </div>
    </div>
  );
};

export default EditOperator;
