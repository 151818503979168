import DateTimeInput from "components/datetime-input/DateTimeInput";
import EmaInput from "components/emaInput/EmaInput";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getElements } from "services/apiEndpoints/Endpoints";
import findError from "utils/findError";

function VehicleForm({ vehicleData, handleChange, errors, carrierName, id }) {
  const { t } = useTranslation();

  const [makeList, setMakeList] = useState([]);
  const [listFleetOwner, setListFleetOwner] = useState([]);

  const getVehicleMake = () => {
    getElements(`/Authority/Vehicle/Makes`).then((res) => {
      setMakeList(res.data);
    });
  };
  const getFleetOwners = () => {
    getElements(`/Authority/Operator/${id}/FleetOwner`).then((res) => {
      let fleetList = (res.data || []).map((c) => ({
        keyValue: c.fleetOwnerId || 0,
        keyName: c.company,
      }));
      // console.log(fleetList);
      setListFleetOwner(fleetList);
    });
  };
  useEffect(getVehicleMake, []);
  useEffect(getFleetOwners, []);

  return (
    <>
      <div className="ema-card">
        <div className="ema-card-title">{t("General Details")}</div>
        <div className="row">
          <div className="col col-4">
            <EmaInput
              label={t("Carrier")}
              name="carrier"
              required
              value={carrierName}
              error={findError("carrierName", errors)}
              onChange={() => {}}
              disabled
            />
          </div>
          <div className="col col-4">
            <EmaInput
              type="select"
              label={t("Ownership")}
              name="ownership"
              options={["Company Vehicle", "Leased Vehicle"]}
              required
              value={vehicleData.ownership}
              onChange={handleChange}
              error={findError("ownership", errors)}
            />
          </div>
          {vehicleData.ownership === "Leased Vehicle" && id && (
            <div className="col col-4">
              <EmaInput
                type="select2"
                label={t("Fleet Owners")}
                name="fleetOwnerId"
                options={listFleetOwner || []}
                required
                value={vehicleData.fleetOwnerId}
                onChange={handleChange}
                error={findError("fleetOwnerId", errors)}
              />
            </div>
          )}
          <div className="col col-4">
            <EmaInput
              label={t("Load Capacity")}
              name="loadCapacity"
              value={vehicleData.loadCapacity}
              onChange={handleChange}
              error={findError("loadCapacity", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Plate Number")}
              name="plateNumber"
              required
              value={vehicleData.plateNumber}
              onChange={handleChange}
              error={findError("plateNumber", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              type="select"
              options={["ET", "DJ"]}
              label={t("Plate State")}
              name="plateState"
              value={vehicleData.plateState}
              onChange={handleChange}
              error={findError("plateState", errors)}
              required
            />
          </div>

          <div className="col col-4">
            <DateTimeInput
              type="date"
              label={t("Inspection Expiration")}
              name="plateExpiration"
              required
              value={vehicleData.plateExpiration}
              error={findError("plateExpiration", errors)}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="ema-card">
        <div className="ema-card-title">{t("Vehicle Specifics")}</div>
        <div className="row">
          <div className="col col-4">
            <EmaInput
              type="select"
              label={t("Make")}
              name="make"
              required
              value={vehicleData.make}
              onChange={handleChange}
              error={findError("make", errors)}
              options={makeList || []}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Model")}
              name="model"
              required
              value={vehicleData.model}
              onChange={handleChange}
              error={findError("model", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Year")}
              name="year"
              required
              value={vehicleData.year}
              onChange={handleChange}
              error={findError("year", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Truck") + " #"}
              name="unitNumber"
              required
              value={vehicleData.unitNumber}
              onChange={handleChange}
              error={findError("unitNumber", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Trailer") + " #1"}
              name="trailer1"
              value={vehicleData.trailer1}
              onChange={handleChange}
              error={findError("trailer1", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Trailer") + " #2"}
              name="trailer2"
              value={vehicleData.trailer2}
              onChange={handleChange}
              error={findError("trailer2", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Chassis Number")}
              name="vin"
              value={vehicleData.vin}
              onChange={handleChange}
              error={findError("vin", errors)}
            />
          </div>
          <div className="col col-4">
            <EmaInput
              label={t("Vehicle Type")}
              name="vehicleType"
              type="select"
              options={["Sleeper", "Daycab", "Straight", "Van"]}
              value={vehicleData.vehicleType}
              onChange={handleChange}
              error={findError("vehicleType", errors)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default VehicleForm;
