import FilterComponent from "components/filterTypes/FilterComponent";
import EmaInput from "components/emaInput/EmaInput";

const OperatorFilters = ({ request, setRequest, t }) => {
  const FilterWrapper = (state, setState, cancelFilter) => {
    const onChange = (e) => {
      const { value, name } = e.target;
      setState({ ...state, [name]: value });
    };

    return (
      <>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            value={state.status}
            name="status"
            label={t("Status")}
            onChange={onChange}
            options={["Pending Authorization", "Authorized", "Revoked"]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter("status");
            }}
          />
        </div>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            value={state.authority_type}
            name="authority_type"
            label={t("Authority Type")}
            onChange={onChange}
            options={["Carrier", "Forwarder", "Shipper", "Fleet Owner"]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter("authority_type");
            }}
          />
        </div>
        <div className="cancellable-filter">
          <EmaInput
            value={state.tin}
            name="tin"
            label={t("TIN")}
            maxLength={10}
            onChange={onChange}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter("tin");
            }}
          />
        </div>
      </>
    );
  };
  return (
    <div className="util-filter-cont">
      <FilterComponent
        request={request}
        setRequest={setRequest}
        title="Filters"
        renderOptions={FilterWrapper}
        selected={request.status || request.authority_type || request.tin}
      />
    </div>
  );
};

export default OperatorFilters;
