// Export enum to avoid typos when referencing environments throughout the app.
const envEnum = {
  local: "local",
  localhost: "localhost",
  sandbox: "sandbox",
  qa: "qa",
  staging: "staging",
  prod: "prod",
};

function getEnvironment() {
  const { hostname, port } = window.location;
  if (hostname.includes(envEnum.local) || port === "3000" || port === "5000")
    return envEnum.local;
  if (hostname.includes(envEnum.sandbox)) return envEnum.sandbox;
  if (hostname.includes(envEnum.qa)) return envEnum.qa;
  if (hostname.includes(envEnum.staging)) return envEnum.staging;
  return envEnum.prod;
}

const env = getEnvironment();

const isLocalOrDev =
  env === envEnum.local || envEnum.localhost || env === envEnum.sandbox;

export { env, isLocalOrDev, envEnum };
