import Loading from "components/loading/Loading";
import SliderPanel from "components/sliderPanel/SliderPanel";
import { useState, useEffect } from "react";
import { getElements } from "services/apiEndpoints/Endpoints";
import NewVehicle from "view/vehicles/NewVehicle";
import TableVehicle from "view/vehicles/TableVehicle";

const Vehicles = ({ openVehicleForm, setOpenVehicleForm, operator }) => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    status: "",
    from_utc: "",
    to_utc: "",
    limit: 50,
    offset: 1,
  });
  const getVehicles = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();
    getElements(
      `/authority/${operator.operatorId}/Vehicle?operator_id=${operator.operatorId}&` +
        queryStr
    ).then((res) => {
      setVehicles(res.data);
      setLoading(false);
    });
  };

  useEffect(getVehicles, [request]);
  return (
    <div>
      {loading ? (
        loading
      ) : (
        <>
          <TableVehicle
            vehicles={vehicles}
            getVehicles={getVehicles}
            updateVehiclesNDashboard={() => {
              getVehicles();
            }}
            hideColumn
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
      <SliderPanel setIsOpen={() => {}} isOpen={openVehicleForm}>
        <NewVehicle
          setIsOpen={setOpenVehicleForm}
          operator={operator}
          getVehicles={getVehicles}
        />
      </SliderPanel>
    </div>
  );
};

export default Vehicles;
