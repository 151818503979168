import CellBuilder from "components/cell-builder/CellBuilder";
import CustomPagination from "components/custom-pagination/CustomPagination";
import Loading from "components/loading/Loading";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { getElements } from "services/apiEndpoints/Endpoints";

const Commodities = () => {
  const [data, setData] = useState();
  const [request, setRequest] = useState({
    limit: 50,
    offset: 1,
  });
  const [loading, setLoading] = useState(Loading);
  const { t } = useTranslation();

  const getCommodities = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
    }).toString();
    getElements("/Marketplace/commodity?" + queryStr)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(getCommodities, [request]);

  const columns = [
    {
      name: "Commodity Name",
      selector: (row) => CellBuilder(row.commodityName),
      grow: 2,
    },
    {
      name: "Commodity Code",
      selector: (row) => CellBuilder(row.commodityCode || ""),
      grow: 2,
    },
    {
      name: "Category",
      selector: (row) => CellBuilder(row.commodityCategory || ""),

      grow: 3,
    },
    {
      name: "Export",
      selector: (row) => CellBuilder(row.isExport ? "YES" : "NO"),

      grow: 1,
    },
  ];
  return (
    <div>
      <div className="title-bar">
        <strong className="title-label">{t(`Commodities`)}</strong>
      </div>
      {loading ? (
        loading
      ) : (
        <div className="summary-list">
          <DataTable
            columns={columns.map((c) => ({
              ...c,
              name: t(c.name),
            }))}
            data={data}
          />
          <CustomPagination
            setRequest={setRequest}
            request={request}
            nextExists={data?.length >= request.limit}
          />
        </div>
      )}
    </div>
  );
};

export default Commodities;
