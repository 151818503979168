import DataTable from "react-data-table-component";

import CellBuilder from "components/cell-builder/CellBuilder";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import { useState } from "react";
import SliderPanel from "components/sliderPanel/SliderPanel";
import dateFormatter from "utils/dateFormater";
import VehicleDetails from "./VehicleDetails";
import { deleteElement, putElement } from "services/apiEndpoints/Endpoints";
import BorderedText from "components/bordered-text/BorderedText";
import joinStrings from "utils/joinStrings";
import CustomPagination from "components/custom-pagination/CustomPagination";
import { useTranslation } from "react-i18next";

function TableVehicle({
  vehicles,
  getVehicles,
  updateVehiclesNDashboard,
  request,
  setRequest,
  hideColumn,
}) {
  const [vehicleState, setVehicleState] = useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      name: "Vehicle",
      selector: (row) =>
        CellBuilder(
          row.unitNumber,
          joinStrings([row.ownership, row.make], " | ")
        ),
      grow: 2,
    },
    {
      name: "Carrier",
      selector: (row) => CellBuilder(row.carrierName || ""),
      grow: 2,
      hide: hideColumn,
    },
    {
      name: "Chassis Number",
      selector: (row) => {
        return CellBuilder(row.vin || "");
      },
      grow: 2,
      hide: hideColumn,
    },
    {
      name: "Plate",
      selector: (row) => {
        let a = `${row.plateNumber || ""} ${
          row.plateState ? "(" + row.plateState + ")" : ""
        }`;
        return CellBuilder(
          a,
          dateFormatter(row.plateExpiration, "DD MMM YYYY", true)
        );
      },
      grow: 2,
    },
    {
      name: "Last known Location",
      selector: (row) =>
        CellBuilder(
          row.lastKnownLocation || "",
          dateFormatter(row.lastSeen, "DD MMM, HH:mm")
        ),
      grow: 2,
    },

    {
      name: "Status",
      selector: (row) =>
        CellBuilder(
          <BorderedText
            text={row.status}
            classes={(row.status || "").replaceAll(" ", "")}
          />
        ),
      grow: 2,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="table-actions">
          <i
            onClick={() => setVehicleState(row.vehicleId)}
            className="mil-edit-o"
            style={{ cursor: "pointer", fontSize: "21px" }}
          />
          <ElipsisMenu>
            <MenuItem
              title={t("Edit")}
              onClick={() => setVehicleState(row.vehicleId)}
            />
            {row.status === "Revoked" && (
              <MenuItem
                title={t("Re-Authorize")}
                onClick={() =>
                  putElement(
                    `/Authority/Vehicle/${row.vehicleId}/Authorize`
                  ).then(() => updateVehiclesNDashboard())
                }
              />
            )}
            {row.status === "Authorized" && (
              <MenuItem
                title={t("Revoke Authority")}
                onClick={() =>
                  deleteElement(
                    `/Authority/Vehicle/${row.vehicleId}`
                  ).then(() => updateVehiclesNDashboard())
                }
              />
            )}
            {row.status === "Authorized" && (
              <MenuItem
                title={t("Decommission")}
                onClick={() =>
                  putElement(
                    `/Authority/Vehicle/${row.vehicleId}/Decommission`
                  ).then(() => updateVehiclesNDashboard())
                }
              />
            )}
            {(row.status === "Not Authorized" ||
              row.status === "Decommissioned") && (
              <MenuItem
                title={t("Authorize")}
                onClick={() =>
                  putElement(
                    `/Authority/Vehicle/${row.vehicleId}/Authorize`
                  ).then(() => updateVehiclesNDashboard())
                }
              />
            )}
          </ElipsisMenu>
        </div>
      ),
      grow: 1,
    },
  ];

  const getColumns = () => {
    let _columns = columns.filter((col) => !col.hide);
    return _columns.map((col) => ({
      ...col,
      name: t(col.name),
    }));
  };
  return (
    <div className="summary-list ema-table">
      <SliderPanel isOpen={vehicleState} setIsOpen={setVehicleState}>
        <VehicleDetails
          setIsOpen={setVehicleState}
          getVehicles={getVehicles}
          id={vehicleState}
        />
      </SliderPanel>
      <DataTable columns={getColumns()} data={vehicles} />
      <CustomPagination
        setRequest={setRequest}
        request={request}
        nextExists={vehicles?.length >= request.limit}
      />
    </div>
  );
}

export default TableVehicle;
