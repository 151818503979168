import CellBuilder from "components/cell-builder/CellBuilder";
import CustomPagination from "components/custom-pagination/CustomPagination";
import Loading from "components/loading/Loading";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { getElements } from "services/apiEndpoints/Endpoints";
import joinStrings from "utils/joinStrings";

const Ports = () => {
  const [ports, setPorts] = useState();
  const [request, setRequest] = useState({
    limit: 50,
    offset: 1,
  });
  const [loading, setLoading] = useState(Loading);
  const { t } = useTranslation();

  const getPorts = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
    }).toString();
    getElements("/Marketplace/Location?" + queryStr)
      .then((res) => {
        setPorts(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(getPorts, [request]);

  const columns = [
    {
      name: "Location Name",
      selector: (row) =>
        CellBuilder(
          joinStrings(
            [row.locationName, row.locationCode ? `(${row.locationCode})` : ""],
            " "
          )
        ),
      grow: 3,
    },
    {
      name: "Type",
      selector: (row) => CellBuilder(row.locationType || ""),
      grow: 1,
    },
    {
      name: "Address",
      selector: (row) => CellBuilder(row.address || ""),

      grow: 3,
    },
    {
      name: "City",
      selector: (row) => CellBuilder(row.city || ""),

      grow: 1,
    },
    {
      name: "Region",
      selector: (row) =>
        CellBuilder(joinStrings([row.state, row.countryCode], ", ")),
      grow: 3,
    },
  ];
  return (
    <div>
      <div className="title-bar">
        <strong className="title-label">{t(`Ports & Terminals`)}</strong>
      </div>
      {loading ? (
        loading
      ) : (
        <div className="summary-list">
          <DataTable
            columns={columns.map((c) => ({
              ...c,
              name: t(c.name),
            }))}
            data={ports}
          />
          <CustomPagination
            setRequest={setRequest}
            request={request}
            nextExists={ports?.length >= request.limit}
          />
        </div>
      )}
    </div>
  );
};

export default Ports;
