import React from "react";
import "./OperatorDashboard.sass";
import { useTranslation } from "react-i18next";

const OperatorDashboard = ({ response, request, setRequest }) => {
  const { t } = useTranslation();
  const data = [
    {
      label: t("Pending Forwarders"),
      value: response?.pendingForwarders,
      type: "Forwarder",
    },
    {
      label: t("Pending Shippers"),
      value: response?.pendingShippers,
      type: "Shipper",
    },
    {
      label: t("Pending Carriers"),
      value: response?.pendingCarriers,
      type: "Carrier",
    },
    {
      label: t("Pending Fleet Owners"),
      value: response?.pendingFleetOwners,
      type: "Fleet Owner",
    },
    {
      label: t("Total Pending"),
      value: response?.pendingTotal,
      type: "",
    },
  ];

  const checkSelectedCard = (authType) => {
    const { status, authority_type } = request;
    if (authority_type == authType && status == "Pending Authorization") {
      return true;
    } else {
      return false;
    }
  };

  const onClick = (authType) => {
    let isSelected = checkSelectedCard(authType);
    if (isSelected) {
      setRequest({ ...request, status: "", authority_type: "" });
    } else {
      setRequest({
        ...request,
        authority_type: authType,
        status: "Pending Authorization",
      });
    }
  };
  return (
    <div className="operator-dashboard">
      <>
        {data.map((operator, index) => (
          <section
            key={index}
            className={`operator-dashboard-card ${
              checkSelectedCard(operator?.type) ? "selected" : ""
            }`}
            onClick={() => onClick(operator?.type)}
          >
            <div className="operator-dashboard-label">{operator.label}</div>
            <div className="operator-dashboard-count">
              {operator.value || 0}
            </div>
          </section>
        ))}
      </>
    </div>
  );
};

export default OperatorDashboard;
